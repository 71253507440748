import { Component, OnInit, HostListener, OnDestroy, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { ChangePassword } from '../../models/changePassword.model';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { CookieService } from 'ngx-cookie-service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { UserPreferencesService } from '../../../core/services/user-preferences.service';
import { Subscription } from 'rxjs';
import { TokenPayload } from '../../models/tokenPayload.model';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomSnackbarComponent } from '../custom-snackbar/custom-snackbar.component';
import { Title } from '@angular/platform-browser';
import { ManageCookieLogoService } from 'src/app/core/services/manage-cookie-logo.service';
import { RemoteLoggingService } from '../../../core/services/remote-logging.service';
import { FeatureFlagService } from 'src/app/core/services/feature-flag.service';


@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UpdatePasswordComponent implements OnInit, OnDestroy {
  /** Subcription for the User Preference Service */
  prefSub: Subscription;
  /** Subscription property for subscribing and unsubscribing services */
  private readonly subscription: Subscription = new Subscription();
  /** Variable to store Form details*/
  updatePasswordSetupForm: UntypedFormGroup;
  /** Model to inject change password object */
  passwordCredential: ChangePassword = {} as ChangePassword;
  /** Variable to store the Error */
  updateError: string;
  /*Token Model initialisation */
  token: TokenPayload = {} as TokenPayload;
  /** Password Instructions */
  passwordInstructions = ['At least 1 number', 'At least 1 capital letter', 'At least 1 lower case', 'At least 1 special character'];
  /** Activates if the resolution is of a mobile device */
  responsiveView: boolean;
  /*Available Sites*/
  authorizedLocations = ['movePro360', 'mobilifyHR', 'mobilifyUI', 'supplierPortal', 'benefitsBuilder', 'compensationServices', 'atlas'];
  /*To check redirected from authorised site or not*/
  authorisedSite = false;
  /* Login Button Status*/
  loginButtonStatus = true;
  /*For the redirected URL */
  referredURL: string;
  /* Update password error message */
  errorMsg = 'You must enter a password';
  /* Okta session timeout */
  idleTimeoutMinutes = 55;
  /** cookie timeout check time */
  checkCookieInterval: any;
  /** Store the error message for incorrect password change attempts */
  incorrectPasswordError: string;
  /** Used to store appId */
  appId: number;
  /** Stores the strings used in the template */
  templateString = {
    INFORMATION: 'Please use the form below to create a new password so that you can access your account',
    BANNER_TXT: `Technology That Moves You`,
    PASSWORD_INSTRUCTION_TITLE: `Passwords must be at least 10 characters, and must contain the following details:`,
    PASSWORD_ADDITIONAL_INSTRUCTION: `Additionally, your new password cannot include your username or be one of your last 12 passwords.`,
    currentPassword: 'Current Password',
    newPassword: 'New Password',
    confirmNewPassword: 'Confirm New Password',
    CHANGE_PASS_BTN: 'Change Password',
    PASSWORD_NOT_MATCH: 'Password entered does not match new password'
  };

  /** Store the userID of the user on an expired password login when routing directly to component from login page */
  userId: string;
  /** Used to hold logo value */
  logo: string;
  // Detect window size
  @HostListener('window:resize')
  onresize() {
    this.getWindowSize();
  }

  /**Base Constructor for Login Component
  * @param authSvc Authentication Service to authenticate the user details
  */
  constructor(private readonly fb: UntypedFormBuilder,
    private readonly authSvc: AuthenticationService,
    private readonly spinner: NgxSpinnerService,
    private readonly router: Router,
    private readonly authService: AuthenticationService,
    private readonly cookieService: CookieService,
    private readonly userPreferencesService: UserPreferencesService,
    private readonly appConfig: AppConfigService,
    private readonly liveAnnouncer: LiveAnnouncer,
    private readonly snackBar: MatSnackBar,
    private readonly titleService: Title,
    private readonly cookieLogoSvc: ManageCookieLogoService,
    private readonly logSvc: RemoteLoggingService,
    private readonly featureFlagService: FeatureFlagService,
  ) {
    this.userId = this.router.getCurrentNavigation().extras.state && this.router.getCurrentNavigation().extras.state.userId
      ? this.router.getCurrentNavigation().extras.state.userId : null;
  }

  async ngOnInit() {
    this.appId = (sessionStorage.getItem('appId')) ? parseInt(sessionStorage.getItem('appId').replace(/"/g, "")) : 0;
    this.titleService.setTitle('Change Password');
    this.getWindowSize();
    this.createControl();
    this.updateError = '';
    this.prefSub = this.userPreferencesService.getPreference('referrer', false).subscribe(val => {
      // Redirect to authorized destination
      this.referredURL = val.replace(/(\/#|\/|#)$/, '');
      const res = this.cookieLogoSvc.setLogo(this.referredURL);
      if (res) {
        this.logo = val;
      }
    });
    // Listener for removal of token cookie sent by header component
    this.checkForCookies();

  }

  /** creates the form controls */
  createControl(): void {
    this.updatePasswordSetupForm = this.fb.group({
      currentPassword: [null, [Validators.required]],
      newPassword: [null, [Validators.required,
      Validators.pattern('^((?=.{10,})((?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9\s])(?=.*[0-9]))).*$')]],
      confirmNewPassword: [null, Validators.required]
    });
  }

  /** checks the values of the newpassword fields and displays an error if different */
  checkPassword(): void {
    this.updateError = '';
    this.updatePasswordSetupForm.get('newPassword').value === this.updatePasswordSetupForm.get('confirmNewPassword').value ?
      this.updatePasswordSetupForm.controls['confirmNewPassword'].setErrors(null) :
      this.updatePasswordSetupForm.controls['confirmNewPassword'].setErrors({
        notSame: true
      });
  }
  /** clears the error status on the field if it has an error */
  clearError(fieldName): void {
    if (this.updatePasswordSetupForm.controls[fieldName].hasError('invalid')) {
      this.updateError = '';
      this.updatePasswordSetupForm.controls[fieldName].setErrors(null)
    }
  }

  /** handles the errors on the form controls */
  // getErrorMessage(fieldName): (string | undefined) {
  getErrorMessage(fieldName): string | undefined {
    if (fieldName === 'currentPassword') {
      return this.updatePasswordSetupForm.get(fieldName).hasError('required')
        ? this.errorMsg
        : '';
    }
    if (fieldName === 'newPassword') {
      return this.updatePasswordSetupForm.get(fieldName).hasError('required')
        ? this.errorMsg
        : this.updatePasswordSetupForm.get(fieldName).hasError('pattern')
          ? 'You must match password complexity rules'
          : '';
    }
    if (fieldName === 'confirmNewPassword') {
      return this.updatePasswordSetupForm.get(fieldName).hasError('required')
        ? this.errorMsg
        : '';
    }
  }

  /** handles the submission of the change password form */
  async changeUserPassword(): Promise<void> {
    this.spinner.show();
    const _this = this;
    this.passwordCredential.oldPassword = this.updatePasswordSetupForm.controls['currentPassword'].value;
    this.passwordCredential.newPassword = this.updatePasswordSetupForm.controls['newPassword'].value;
    this.subscription.add(
      this.authSvc
        .changeUserPassword(this.passwordCredential)
        .subscribe(
          response => {
            if (!!response) { //401 swallowed by http-error-handler
              let passwordSnackbar
              let message: string
              let snackbarMessage: string
              let remainingPasswordAttempts: number
              let announcementMsg: string
              if (response.error && response.error.statusCode && response.error.statusCode === 403) {
                remainingPasswordAttempts = Number(response.error.remainingAttempts)
                message = response.error.message.toLowerCase()
                if (message.includes('your current password is not entered correctly')) {
                  _this.updatePasswordSetupForm.get('currentPassword').setErrors({
                    notCorrect: true
                  });
                  _this.incorrectPasswordError = `Incorrect password, you will be logged out after ${remainingPasswordAttempts} more incorrect attempts.`
                  announcementMsg = _this.incorrectPasswordError
                } else if (message.includes('new password cannot be the same') || message.includes('password cannot be any of the past')) {
                  _this.updatePasswordSetupForm.get('newPassword').setErrors({
                    invalid: true
                  });
                  _this.updateError = `${response.error.message}.`
                  announcementMsg = _this.updateError
                  _this.getErrorMessage('newPassword')
                } else {
                  _this.updatePasswordSetupForm.get('newPassword').setErrors({
                    invalid: true
                  });
                  _this.updateError = response.error.message + `. you will be logged out after ${remainingPasswordAttempts} more incorrect attempts.`
                  announcementMsg = _this.updateError
                  _this.getErrorMessage('newPassword')
                }
              } else if (response.error && response.error.statusCode && response.error.statusCode !== 403) {
                snackbarMessage = 'We are unable to process your request at this time. Please try again later.'
                this.logSvc.logError(response)
                console.error('Error in change password call')
              } else {
                snackbarMessage = 'Changes saved'
              }

              if (!!announcementMsg) {
                _this.liveAnnouncer.announce(announcementMsg)
                this.spinner.hide()
              }

              if (!!snackbarMessage) {
                passwordSnackbar = this.snackBar.openFromComponent(CustomSnackbarComponent, {
                  horizontalPosition: 'center',
                  verticalPosition: 'bottom',
                  data: snackbarMessage,
                  duration: 5000,
                })
                this.spinner.hide()
                passwordSnackbar.afterDismissed().subscribe(async () => {
                  if (this.appId !== 10) {
                    this.cancel()
                  }
                })
              }

              if (typeof (remainingPasswordAttempts) === 'number' && remainingPasswordAttempts < 1) {
                this.logout()
              }
            }
          })
    );
  }

  /** handles the action on clicking the cancel button */
  cancel(): void {
    this.userPreferencesService.getPreference('referrer', false).subscribe(val => {
      const url = val;
      this.userPreferencesService.getPreference('urlPath', false).subscribe(res => {
        const fullPath = this.appId === 7 ? `${url}` : `${url}#${res}`;
        this.router.navigate(['/externalRedirect', { externalUrl: fullPath }], {
          skipLocationChange: true,
        });
      });
    });
  }
  /** handles the whether the view is responsive */
  getWindowSize(): void {
    const windowWidth = window.innerWidth;
    if (windowWidth <= 959) {
      this.responsiveView = true;
    } else {
      this.responsiveView = false;
    }
  }

  /** To unsubscribe to the subscription and event listener */
  ngOnDestroy(): void {
    if (this.prefSub) {
      this.prefSub.unsubscribe();
    }
    clearInterval(this.checkCookieInterval);
  }

  /* performs a redirect to logout */
  logout(): void {
    this.router.navigate(['logout']);
  }

  /** Check token cookie value status */
  checkForCookies(): void {
    this.checkCookieInterval = setInterval(() => {
      if (!this.cookieService.get('car-ses-tok')) {
        this.logout();
      }
    }, 500);
  }

}
