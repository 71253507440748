import { Injectable, OnDestroy } from '@angular/core';
import { OktaAuth, AccessToken } from '@okta/okta-auth-js'
import { TokenPayload } from 'src/app/public/models/tokenPayload.model';
import { ChangePassword, ForgotPassword } from 'src/app/public/models/changePassword.model';
import { AppConfigService } from './app-config.service';
import { CookieService } from 'ngx-cookie-service';
import { HttpClientModule } from '@angular/common/http';

import { Observable, of, throwError } from 'rxjs';
import { BaseClientService } from 'src/app/core/services/base-client.service';
import { catchError, map } from 'rxjs/operators';
import { RemoteLoggingService } from 'src/app/core/services/remote-logging.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService implements OnDestroy {
  /** Variable to store the token  */
  public authClient: OktaAuth;
  /** Variable to store the transaction*/
  private transaction: any;

  constructor(
    private readonly http: HttpClientModule,
    private readonly appConfig: AppConfigService,
    private readonly cookieService: CookieService,
    protected baseClient: BaseClientService,
    private readonly logSvc: RemoteLoggingService,
  ) {
    this.authClient = new OktaAuth({
      clientId: String(this.appConfig.getConfig('oktaClientId')),
      issuer: String(this.appConfig.getConfig('oktaUrl')),
      redirectUri: String(this.appConfig.getConfig('oktaRedirectUri')),
      postLogoutRedirectUri: String(this.appConfig.getConfig('oktaRedirectUri')),
      tokenManager: {
        storage: 'sessionStorage',
        autoRenew: false
      }
    });
    this.authClient.authStateManager.subscribe(authState => {
      // handle emitted latest authState
    });
    if (!this.authClient.isLoginRedirect()) {
      // Trigger an initial authState change event when the app startup
      this.authClient.authStateManager.updateAuthState();
    }
  }

  /** Component Angular destructor lifecycle hook */
  ngOnDestroy(): void {
    if (this.authClient.authStateManager.subscribe) {
      this.authClient.authStateManager.unsubscribe();
    }
  }

  /** Service call for login */
  async login(user: TokenPayload) {
    try {
      const data = {
        username: user.email,
        password: user.password
      };

      this.transaction = await this.authClient.signInWithCredentials(data);
      return await this.getToken(this.transaction);
    } catch (err) {
      return err;
    }
  }

  /** Service call for signOut and revoke */
  async signOut() {
    // We need to revoke the token before closing it.
    await this.authClient.revokeAccessToken();
    await this.authClient.closeSession()
      .then(() => {
      })
      .catch(e => {
        if (e.xhr && e.xhr.status === 429) {
          console.error('Too many requests.');
        }
      });
  }

  /** Service call for to get tokens */
  async getToken(transactions) {
    let receivedTokens;
    if (transactions && transactions.status === 'SUCCESS') {
      let oldToken: AccessToken = await this.getOldToken()
      await this.authClient.token.getWithoutPrompt({
        responseType: ['code', 'token', 'id_token'],
        sessionToken: transactions.sessionToken,
        scopes: ['openid', 'offline_access', 'email'],
      })
        .then(res => {
          receivedTokens = res.tokens;
          if (receivedTokens.idToken && receivedTokens.accessToken) {
            this.authClient.tokenManager.add('idToken', receivedTokens.idToken);
            this.authClient.tokenManager.add('accessToken', receivedTokens.accessToken);
            this.revokeOldToken(oldToken)
          }
        });
    }
    return {
      transaction: transactions,
      tokens: receivedTokens
    };
  }

  /** Service call for SSO authentication */
  async trySso() {
    let oldToken: AccessToken = await this.getOldToken()
    return await this.authClient.token.getWithoutPrompt().then(tokenOrTokens => {
      if (tokenOrTokens) {
        this.authClient.tokenManager.setTokens({
          accessToken: tokenOrTokens.tokens.accessToken,
          idToken: tokenOrTokens.tokens.idToken
        })
        this.revokeOldToken(oldToken)
      }
      console.log('getWithoutPrompt()', tokenOrTokens); // Leave this debug code in place
      return tokenOrTokens;
    }).catch(err => {
      // console.error(err); // Leave this debug code in place -- commented out due to error being displayed regardless
      return; // Not authenticated
    });
  }

  /** Call to handle change user password */
  public changeUserPassword(passwordDetail: ChangePassword) {
    const urlStr: string = sessionStorage.getItem('urlPath')?.replace(/"/g, "");
    const appid: number = parseInt(sessionStorage.getItem('appId')?.replace(/"/g, ""));
    let appContext: string;
    if (urlStr) {
      switch (appid) {
        case 1:
        case 5:
          appContext = urlStr.replace(/^(?:[^/]*\/)*\s*/, '');
          break;
        case 7:
        case 8:
          appContext = urlStr.replace(/^(?:[^/]*\/)*\s*/, '').split('profile')[1];
          break;
        default:
          appContext = this.cookieService.get('app-context');
          break;
      }
    } else {
      appContext = this.cookieService.get('app-context');
    }
    const headers = {
      'app-context': `${appContext}`,
      'authorization': this.cookieService.get('car-ses-tok')
    };
    const body = {
      'oldPassword': {
        'value': passwordDetail.oldPassword
      },
      'newPassword': {
        'value': passwordDetail.newPassword
      }
    };

    const url: string = String(this.appConfig.getConfig('changePasswordEndpoint'));
    return this.baseClient.postUnauthorizedHandler<any>(url, body, headers).pipe(
      map(r => r.body),
      catchError((err, source) => {
        this.logSvc.logError(err);
        return of(err);
      })
    );
  }

  /** Call to handle forgot password */
  public forgotPassword(reqObjDetail: ForgotPassword) {

    const body = {
      'username': reqObjDetail.username,
      'factorType': reqObjDetail.factorType,
      'baseURL': reqObjDetail.baseURL
    };

    const url: string = String(this.appConfig.getConfig('forgotPasswordEndpoint'));
    return this.baseClient.post<any>(url, body).pipe(
      map(r => r.body),
      catchError((err, source) => {
        this.logSvc.logError(err);
        return of(err);
      })
    );
  }
  /** Call to handle unlock account */
  unlockAccount(reqObjDetail: ForgotPassword): Observable<any> {
    const body = {
      'username': reqObjDetail.username,
      'factorType': reqObjDetail.factorType,
      'baseURL': reqObjDetail.baseURL
    };

    const url: string = String(this.appConfig.getConfig('unlockAccountEndpoint'));

    return this.baseClient.post<any>(url, body)
      .pipe(map(
        results => results
      ))
      .pipe(catchError(
        (error: Response) => {
          return throwError(() => error);
        }
      ));
  }

  /** To reset the incorrect password attempts
   * @param headers for the request
   */
  resetPasswordAttempts(headers: any): Observable<any> {
    const url = String(this.appConfig.getConfig('resetPasswordEndpoint'));
    return this.baseClient.post<any>(url, null, headers).pipe(
      map(r => r.body),
      catchError((err, source) => {
        const empty: any = null;
        this.logSvc.logError(err);
        return of(empty);
      })
    );
  }

  /** Service call to refresh session */
  async refreshSession() {
    let oldToken: AccessToken = await this.getOldToken()
    return this.authClient.token.renew(oldToken).then(freshToken => {
      console.log('refreshSession()', freshToken); // Leave this debug code in place
      if (freshToken) {
        this.revokeOldToken(oldToken)
        return freshToken;
      }
    }).catch(err => {
      // console.error(err); // Leave this debug code in place -- commented out due to error being displayed regardless
      return; // Not authenticated
    });
  }

  /** Service call to revoke old token */
  async revokeOldToken(accessToken: AccessToken) {
    if (accessToken) {
      console.log('revokeOldToken()')
      this.authClient.revokeAccessToken(accessToken)
    }
  }

  /** Service call to get old token */
  async getOldToken() {
    let oldToken: AccessToken
    await this.authClient.tokenManager.getTokens().then(tokens => {
      if (tokens && tokens.accessToken) {
        oldToken = tokens.accessToken
      }
    });
    return oldToken
  }

}
