import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { User } from '../../core/models/User';
@Injectable({
  providedIn: 'root'
})
export class ManageUserSharedService {

  /** Instance of BehaviorSubject of type UserDetails */
  userDetails = new BehaviorSubject<User>(null);
  /** UserDetails as observable */
  loginUserDetails = this.userDetails.asObservable();

  
  public gtmImplementService = new ReplaySubject(1);
  public gtmData$ = this.gtmImplementService.asObservable();

  /**
   * Base Constructor
   */
  constructor() { }

  /**
   * Function to Update the UserDetails subject
   * @param data updated User
   */
  updateData(data: User) {
    this.userDetails.next(data);
  }
}
