import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BaseClientService } from 'src/app/core/services/base-client.service';
import { catchError, map } from 'rxjs/operators';
import { RemoteLoggingService } from 'src/app/core/services/remote-logging.service';
import { UserValidation } from '../models/UserValidation';
import { User } from '../models/User';

/** user service to post details of login to the server
 * header
 */
@Injectable({
    providedIn: 'root'
})
export class SelfRegistrationValidationService {
    /**
     * base constructor
     */
    constructor(
        protected baseClient: BaseClientService,
        private readonly logSvc: RemoteLoggingService,
    ) { }
   /**
   * Handle sending of OTP
   * @param user user to send the OTP code to
   * @param sendPhone whether the OTP code should be sent to the email (instead of phone)
   * @param catchError error from API call
   */
    sendOTP(user: User, sendPhone: boolean): Observable<boolean> {
        const path: string = '/otp/generatetotp'
        return this.baseClient.post<boolean>(path, user).pipe(
            map(r => r.body),
            catchError((err, source) => {
                const empty: boolean = null;
                this.logSvc.logError(err);
                return of(empty);
            })
        );
    }
   /**
   * Handle validation of OTP
   * @param user useriD of the user to verify and OTP code entered to verify
   * @param sendPhone whether the OTP code should be sent to the email (instead of phone)
   * @param catchError error from API call
   */
    validateOTP(user: UserValidation, sendPhone: boolean): Observable<any> {
        const path: string = '/otp/validatetotp'
        return this.baseClient.postExt<any>(path, user, 'validating otp', true).pipe(
            map(r => r.body),
            catchError((err, source) => {
                const empty: any = null;
                this.logSvc.logError(err);
                return of(empty);
            })
        );
    }
}
