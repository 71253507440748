import { Component, OnInit, OnDestroy, ViewEncapsulation, NgZone } from '@angular/core';
import { Validators, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ManageUserSharedService } from '../../../../core/services/manage-user-shared.service';
import { User } from '../../../../core/models/User';
import { TextEncoder } from 'text-encoding'; // Added for Edge browser compatiblity
import { UserPreferencesService } from '../../../../core/services/user-preferences.service';
import { ManageCookieLogoService } from 'src/app/core/services/manage-cookie-logo.service';
import { Subscription, firstValueFrom } from 'rxjs';
import { OktaSignIn, WidgetOptions } from '@okta/okta-signin-widget';
import { Title } from '@angular/platform-browser';
import { FeatureFlagService } from 'src/app/core/services/feature-flag.service';
import { ErrorMapping } from 'src/app/core/constants'
import { SelfRegistrationSetupService } from 'src/app/core/services/self-registration-setup.service';
import { CustFederationService } from 'src/app/core/services/cust-federation.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RemoteLoggingService } from 'src/app/core/services/remote-logging.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-self-registration-existing-user-login',
  templateUrl: './self-registration-existing-user-login.component.html',
  styleUrls: ['./self-registration-existing-user-login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SelfRegistrationExistingUserLoginComponent implements OnInit, OnDestroy {
  /** Subscription property for subscribing and unsubscribing services */
  private readonly subscription: Subscription = new Subscription();
  /** Subscription for preference */
  prefSub: Subscription;
  /** Password display style, clear text or masked */
  hide: boolean;
  /*Available Sites*/
  authorizedLocations = ['movePro360', 'mobilifyHR', 'mobilifyUI', 'supplierPortal', 'benefitsBuilder', 'compensationServices', 'domain', 'atlas'];
  /*To check redirected from authorised site or not*/
  authorisedSite = false;
  /*For the redirected URL */
  referredURL: string = '';
  /* For the Self registraion Login redirecting */
  selfRegLoginURL: string;
  /* Request Email Button Status*/
  requestEmailStatus = false;
  /* Okta session timeout */
  idleTimeoutMinutes = 55;
  /* Displays message to user (i.e. idle or session timeout) */
  message: string;
  /*Inactive user flag*/
  inactiveUser = false;
  /*Edge Chromium flag*/
  isEdgeChromium = false;
  /* Stores the route data */
  routeSub: any
  /* Okta widget */
  signInWidget: OktaSignIn;
  /* Okta widget config */
  signInWidgetConfig: WidgetOptions = {};
  /** Variable to store PartyId */
  partyId: string;
  /** userName field enabled for the below Customer Roles */
  userNameEnabledRoles = ['transferee', 'legacy-transferee'];
  // new user to be federated
  newUser: User;
  /**flag to track customer federation status */
  custFedStatus: number = 0;
  /**
 * Initializes the value
 * @param router Instance Router
 */
  /**Base Constructor for Login Component
   * @param authSvc Authentication Service to authenticate the user details
   */
  /** Login Form.  Contains email and password fields, which are both required. */
  loginForm = this.fb.group({
    email: new UntypedFormControl('',
      Validators.compose([
        Validators.required,
        Validators.pattern('^[A-Za-z0-9!#$%&\'*+/=?^_‘{|}~-]+(?:\\.[A-Za-z0-9!#$%&\'*+/=?^_‘{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])$')
      ])),
    password: ['', [Validators.required]]
  });
  templateString = {
    BNNER_TXT: `Technology That Moves You`,
    MOVEPRO360_TXT: `Welcome to MovePro360 ─ your centralized mobility hub and single source of truth ` +
      `for all your relocation and global talent mobility goals. Optimize move outcomes, anticipate ` +
      `needs, access predictive insights, and much more.`
  };
    /** Used to display error when federate user api got failed */
  apiError: string = 'Additional configuration is needed to link these accounts. Please contact the Cartus Help Desk for further instructions.';
  /** Used to hold Candidate Move Data */
  userDetails: User;
  /** Used to hold logo config value */
  logoConfig: any;
  /** Used to determine if the Google sign in is displayed */
  showGoogleButton: boolean = false;
  /** Used to hold the help links to display when the Google sign in is displayed */
  googleHelpLinks: string[] = ['Help'];

  /**
   * Base constructor
   * @param snackBar MatSnackBar
   * @param logSvc RemoteLoggingService
   * @param spinner to get NgxSpinner
   */
  constructor(
    private readonly authSvc: AuthenticationService,
    private readonly spinner: NgxSpinnerService,
    private readonly fb: UntypedFormBuilder,
    private readonly router: Router,
    private readonly appConfig: AppConfigService,
    private readonly moveSharedService: ManageUserSharedService,
    private readonly userPreferencesService: UserPreferencesService,
    private readonly route: ActivatedRoute,
    private readonly titleService: Title,
    private readonly cookieLogoSvc: ManageCookieLogoService,
    private readonly featureFlagService: FeatureFlagService,
    private _ngZone: NgZone,
    private readonly registrationSetupService: SelfRegistrationSetupService,
    private readonly CustFederationService: CustFederationService,
    private readonly snackBar: MatSnackBar,
    private readonly logSvc: RemoteLoggingService,
    private readonly location: Location
  ) {
    /** Added for Edge Browser compatiblity */
    if (!window['TextEncoder']) {
      window['TextEncoder'] = TextEncoder;
    }
  }

  /** Login Component's Init Function */
  async ngOnInit() {
    //Gathering Party Id from URL
    this.partyId = this.route.snapshot.url[1].path;
    const userinfo: User = await this.getUserDetails(this.partyId);
    this.newUser = userinfo;
    // Deleting cookies on ngOnInit
    this.cookieLogoSvc.removeAllCookies();
    this.titleService.setTitle('Login');
    /* Region Google */
    const oktaUrl = String(this.appConfig.getConfig('oktaUrl'))
    const oktaId = String(this.appConfig.getConfig('oktaClientId'))
    const oktaRedirectUrl = String(this.appConfig.getConfig('oktaRedirectUri'))
    const oktaIdpRedirectUrl = String(this.appConfig.getConfig('oktaIdpRedirectUrl'))
    const oktaGoolgleIdpId = String(this.appConfig.getConfig('oktaGoolgleIdpId'))
    /* End Region */
    this.spinner.show();
    // const googleIDPFeatureFlag = await this.getGoogleIDPFeatureFlag();
    let routeData
    this.routeSub = this.route.data.subscribe(v => routeData = v);
    routeData['googleIdp'] ? this.showGoogleButton = true : this.showGoogleButton = false
    this.isEdgeChromium = this.detectEdgeChromium();
    // Initialize Okta widget
    this.signInWidgetConfig = {
      language: 'en',
      i18n: {
        'en': {
          'primaryauth.title': 'Enter your credentials to log in',
          'primaryauth.submit': 'Log In',
          'primaryauth.username.placeholder': "Existing Username",
          'primaryauth.password.placeholder': "Existing Password"
        }
      },
      features: {
        idpDisovery: true,
        rememberMe: false,
        selfServiceUnlock: true,
        multiOptionalFactorEnroll: true,
        disableAutocomplete: true
      },
      baseUrl: oktaUrl.split('/oauth2')[0], // Derive from issuer
      clientId: oktaId,
      redirectUri: this.showGoogleButton ? oktaIdpRedirectUrl : oktaRedirectUrl,
      useInteractionCodeFlow: false,
      useClassicEngine: true,
      username: userinfo.emailAddress,
      authParams: {
        issuer: oktaUrl,
        responseType: ['id_token', 'token'],
        scopes: ['openid', 'email', 'profile']
      },
      helpLinks: {
        help: '/#/contactUs',
        forgotPassword: this.showGoogleButton ? '/#/contactUs' : null,
        unlock: this.showGoogleButton ? '/#/contactUs' : null,
        custom: [
          {
            text: 'Go back to Registration page',
            href: `/#/registration/${this.partyId}`
          },
        ]

      },
      idps: this.showGoogleButton ? [
        { type: 'GOOGLE', id: oktaGoolgleIdpId }
      ] : null
    };
    this.signInWidget = new OktaSignIn(this.signInWidgetConfig);
    /* Region Google IDP */
    this.signInWidget.on('ready', () => {
      if (this.showGoogleButton) {
        const widgetFormEl = document.querySelector('form');
        const widgetDividerEl: HTMLElement = document.querySelector('.auth-divider');
        const loginButton: HTMLElement = document.querySelector('.social-auth-google-button');
        const helpLinkEls = document.querySelectorAll('#help-links-container li') as NodeList;
        if (widgetFormEl) {
          widgetFormEl.style.display = 'none';
          widgetFormEl.style.visibility = 'hidden';
        }
        if (widgetDividerEl) {
          widgetDividerEl.style.display = 'none';
          widgetDividerEl.style.visibility = 'hidden';
        }
        if (loginButton && !loginButton.hasAttribute('clickevent')) {
          loginButton.setAttribute('clickevent', '');
          loginButton.addEventListener('click', () => { this.message = null });
        }
        if (helpLinkEls.length > 0) {
          helpLinkEls.forEach((helpLinkEl: HTMLElement) => {
            const displayLink = this.googleHelpLinks.includes(helpLinkEl.textContent);
            helpLinkEl.style.display = displayLink ? 'list-item' : 'none';
            helpLinkEl.style.visibility = displayLink ? 'visible' : 'hidden';
          });
        }
      }
    });
    /* End Region */
    /* Region Okta Account Creation Error */
    this.signInWidget.on('afterError', (context: any, error: any) => {
      if (this.showGoogleButton
        && context.controller && context.controller === 'primary-auth'
        && error.name && error.name === 'OAUTH_ERROR'
        && error.message) {
        this.message = this.getUserFriendlyMessage(error.message)
      }else{
        this.disableEmailCheck();
      }
    });

    this.signInWidget.on('afterRender', () => {
      this.disableEmailCheck();
    })

    /* End Region */
    this.signInWidget.renderEl(
      { el: '#widget' },
      (res: any) => {
        this.onLoginResponse(res);
      },
      (err: any) => {
        console.error(err);
      }
    );
    // Set logo based on referrer
    this.prefSub = this.userPreferencesService.getPreference('referrer', false).subscribe(val => {
      this.referredURL = val ? val.replace(/(\/#|\/|#)$/, '') : '';
      const res = this.cookieLogoSvc.setLogo(this.referredURL)
      if (res) {
        this.logoConfig = { logo: res, type: 'main' }
      }
    });

    this.spinner.hide(); // No SSO Check

  }

  /**
     * To get the GoogleIDP feature flag
     */
  // public async getGoogleIDPFeatureFlag() {
  //   return await this.featureFlagService.getgoogleIDPFeatureFlag();
  // }

  /** Component Angular destructor lifecycle hook */
  ngOnDestroy(): void {
    if (this.signInWidget) {
      this.signInWidget.remove();
    }
    if (this.prefSub) {
      this.prefSub.unsubscribe();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
  }

  disableEmailCheck(): void {
    if (this.newUser && !(this.userNameEnabledRoles.indexOf(this.newUser.roleName) >= 0)) {
      //disable username Field for this Roles.
      document.getElementById('okta-signin-username').setAttribute("disabled", "disabled");
    }
  }

  /** detect Edge Chromium for SP-341: Buttons and text indistingushable in High Contrast Mode */
  detectEdgeChromium(): boolean {
    const agent = window.navigator.userAgent.toLowerCase();
    return agent.indexOf('edg') > -1 ? true : false;
  }

  onLoginResponse(res: any): void {

    if (res.status === 'FORGOT_PASSWORD_EMAIL_SENT') {
      // TBD
    }
    if (res.status === 'UNLOCK_ACCOUNT_EMAIL_SENT') {
      // TBD
    }
    if (res.status === 'PASSWORD_EXPIRED') { // Is this still needed?
      if (res.tokens.idToken.claims.email.toLowerCase().includes('cartus.com')) {
        this.router.navigate(['/updatePassword']);
      }
    }
    if (res.status === 'LOCKED_OUT') { // Is this still needed?
      if (res.tokens.idToken.claims.email.toLowerCase().includes('cartus.com')) {
        this.requestEmailStatus = true;
      }
    }
    if (res.status === 'SUCCESS') {
      const newpartyId = this.newUser.userId;
      this.subscription.add(
        this.CustFederationService.custDetails(res.tokens.accessToken.accessToken).subscribe(response => {
          const existUserPartyId = response[0].party._id;
          const existingRoles = response[0].party.roles;
          const existingRoleName = existingRoles.find(roles => roles.name.includes('legacy-supplier-contact'));
          
          if (!response || response.length === 0) {
            console.error('Unrecognized userDetails');
          }else if (this.newUser.roleName === 'legacy-supplier-contact' && this.newUser.roleName !== existingRoleName.name) {
            console.error('Unrecognized roles');
                this.snackBar.open(
                  this.apiError,
                  undefined,
                  { duration: 5000 }
                )
                this.changeStep();
          }
          else { // other error
            this.subscription.add(this.CustFederationService.federateUser(existUserPartyId, newpartyId, this.newUser, res.tokens.accessToken.accessToken).subscribe(response => {
              if (response) {
                this.logout();
                this.custFedStatus = 1;
              } else {
                let snackbarMessage: string = this.apiError;
                let logError: boolean = true;
                this.snackBar.open(
                  snackbarMessage,
                  undefined,
                  { duration: 5000 }
                )
                if (!!logError) {
                  this.logSvc.logError(response)
                }
                this.changeStep();
              }
            }));
          }
        }));
    }
    this.spinner.hide();
  }
  logout(): void {
    this.cookieLogoSvc.removeAllCookies();
    this.authSvc.signOut();
    this.cookieLogoSvc.removeCookies(['car-ses-tok']);
  }

  changeStep(): void {
    sessionStorage.setItem('currentStep', JSON.stringify(1));
    const stepVal = `registration/${this.partyId}/step/1`;
    this.router.navigate([stepVal]);

  }

  getUserDetails(partyId: string) {
    return firstValueFrom(this.registrationSetupService
      .getUser(partyId));

  }

  public getUserFriendlyMessage(message: string): string {
    return ErrorMapping[message] || ErrorMapping['default']
  }
}