<div fxLayout="row" class="snack-content">
  <div fxLayout="column" class="sent">{{data}}</div>

  <div class="snack-icons">
    <div class="spinner-container">
      <div class="loader"></div>
    </div>
    <button (click)="dismiss()" class="unstyled-button">
      <mat-icon id="dismiss" class="loader-close-icon">close</mat-icon>
    </button>
  </div>
</div>
