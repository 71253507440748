import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { timer } from 'rxjs';
import { copyright } from 'src/app/core/constants';

@Component({
  selector: 'app-privacy-notice',
  templateUrl: './privacy-notice.component.html',
  styleUrls: ['./privacy-notice.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PrivacyNoticeComponent implements OnInit {
  /**property to print copyright string */
  readonly copyright = copyright;
  /** Used to hold logo config value */
  logoConfig: any;

  constructor(
    private readonly titleService: Title,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Privacy Policy');
    this.logoConfig = { logo: 'cartus', type: 'main' }
    this.spinner.show();
    timer(2000).subscribe(() => { this.spinner.hide(); } );
  }

}
