import {BrowserModule, Title} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgIdleModule} from '@ng-idle/core';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import {FlexLayoutModule} from '@angular/flex-layout';
import {HttpClientModule} from '@angular/common/http';
import {LoginComponent} from './public/components/login/login.component';
import {APP_BASE_HREF, HashLocationStrategy, LocationStrategy} from '@angular/common';
import {LogoutComponent} from './public/components/logout/logout.component';
import {ExternRouteComponent} from './public/components/extern-route/extern-route.component';
import {CookieService} from 'ngx-cookie-service';
import {SelfRegistrationComponent} from './public/components/selfRegistration/self-registration.component';
import {SelfRegistrationValidationComponent} from './public/components/selfRegistration/selfRegistration-validateUserComponent/self-registration-validate-user.component';
import {SelfRegistrationCreateUserComponent} from './public/components/selfRegistration/selfRegistration-CreateUserComponent/self-registration.createUser.component';
import {SelfRegistrationSuccessComponent} from './public/components/selfRegistration/selfRegistration-SuccessComponent/self-registration-success.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {LeftPanelComponent} from './public/components/left-panel/left-panel.component';
import {UpdatePasswordComponent} from './public/components/update-password/update-password.component';
import {UpdatePasswordSucessPageComponent} from './public/components/update-password-sucess-page/update-password-sucess-page.component';
import {PrivacyNoticeComponent} from './public/components/privacy-notice/privacy-notice.component';
import {DuplicateRegistrationComponent} from './public/components/duplicateRegistration/duplicate-registration.component';
import {InvalidRegistrationComponent} from './public/components/invalidRegistration/invalid-registration.component';
import {FooterComponent} from './public/components/footer/footer.component';
import {ContactUsComponent} from './public/components/contact-us/contact-us.component';
import {PrivacyStatementComponent} from './public/components/privacy-statement/privacy-statement.component';
import {TermsOfUseComponent} from './public/components/terms-of-use/terms-of-use.component';
import {HeaderComponent} from './public/components/header/header.component';
import {ToastrModule} from 'ngx-toastr';
import {SessionTimeoutComponent} from './public/components/session-timeout/session-timeout.component';
import {CustomSnackbarComponent} from './public/components/custom-snackbar/custom-snackbar.component';
import {LogoComponent} from './public/components/logo/logo.component';
import {SpringboardComponent} from './public/components/springboard/springboard.component';
import {SpringboardAppComponent} from './public/components/springboard/springboard-app/springboard-app.component';
import {UserContextComponent} from './public/components/user-context/user-context.component';
import {AvatarModule} from './public/components/avatar/avatar.module';
import {SpringboardTimeoutComponent, FormatTimePipe} from './public/components/springboard/springboard-timeout/springboard-timeout.component';
import { SelfRegistrationExistingUserLoginComponent } from './public/components/selfRegistration/self-registration-existing-user-login/self-registration-existing-user-login.component';
import { DelegateLegalAgreementComponent } from './public/components/delegate-legal-agreement/delegate-legal-agreement.component';
import { DelegateOptOutComponent } from './public/components/delegate-opt-out/delegate-opt-out.component';
import { ConsentComponent } from './public/components/selfRegistration/consent/consent.component';
import { ForgotpasswordComponent } from './public/components/forgotpassword/forgotpassword.component';
import { UnlockaccountComponent } from './public/components/unlockaccount/unlockaccount.component';

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        LogoutComponent,
        ExternRouteComponent,
        SelfRegistrationComponent,
        SelfRegistrationValidationComponent,
        SelfRegistrationCreateUserComponent,
        SelfRegistrationSuccessComponent,
        LeftPanelComponent,
        UpdatePasswordComponent,
        UpdatePasswordSucessPageComponent,
        PrivacyNoticeComponent,
        DuplicateRegistrationComponent,
        InvalidRegistrationComponent,
        SessionTimeoutComponent,
        FooterComponent,
        HeaderComponent,
        ContactUsComponent,
        PrivacyStatementComponent,
        TermsOfUseComponent,
        CustomSnackbarComponent,
        LogoComponent,
        SpringboardComponent,
        SpringboardAppComponent,
        UserContextComponent,
        SpringboardTimeoutComponent,
        FormatTimePipe,
        SelfRegistrationExistingUserLoginComponent,
        DelegateLegalAgreementComponent,
        DelegateOptOutComponent,
        ConsentComponent,
        ForgotpasswordComponent,
        UnlockaccountComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MatToolbarModule,
        MatFormFieldModule,
        MatInputModule,
        MatOptionModule,
        MatSelectModule,
        MatIconModule,
        MatButtonModule,
        MatCardModule,
        MatCheckboxModule,
        MatTableModule,
        MatMenuModule,
        MatSortModule,
        AvatarModule,
        MatDividerModule,
        MatSnackBarModule,
        MatStepperModule,
        // PersistenceModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        NgxSpinnerModule,
        NgIdleModule.forRoot(),
        ToastrModule.forRoot({
            timeOut: 5000,
            positionClass: 'toast-bottom-center',
            maxOpened: 3,
            preventDuplicates: true,
        }),
        AppRoutingModule // I must be last!! https://angular.io/guide/router#module-import-order-matters
    ],
    providers: [
        Title,
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        CookieService,
        { provide: APP_BASE_HREF, useValue: '/' }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
