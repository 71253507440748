import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from './base-service';
import { ApiConfigService } from './api-config.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpErrorHandlerService } from './http-error-handler.service';

/**
 * Used to make generic standard API calls.  The base URL for the service calls is based on the configuration.
 */
@Injectable({
  providedIn: 'root'
})
export class BaseClientService extends BaseService {

  /**
   * base constructor
   * @param config API Config service injector
   * @param http HTTP Client injector
   * @param errorHandler HTTP error handler injector
   */
  constructor(config: ApiConfigService, http: HttpClient, private readonly errorHandler: HttpErrorHandlerService) {
    super(config, http);
  }

  /** Run a GET API call, expecting a response with a single model
   * @param route The endpoint for the request (ie. - '/v1/reports_recent')
   * @param action The action that is performing the request
   * @return A response containing the expected model (single)
   */
  getById<TResponseModel>(route: string, action = 'error executing requests'): Observable<HttpResponse<TResponseModel>> {
    return this.http.get<TResponseModel>(this.rootUrl + route, { params: this.newParams(), observe: 'response', responseType: 'json' })
      .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
  }

  /** Run a GET API call, expectiing a response with an array of the expected model
   * @param route The endpoint for the request (ie. - '/v1/reports_recent')
   * @param action The action that is performing the request
   * @return A response containing the expected models (array)
   */
  get<TResponseModel>(route: string, action = 'error executing requests'): Observable<HttpResponse<Array<TResponseModel>>> {
    return this.http.get<TResponseModel>(this.rootUrl + route, { params: this.newParams(), observe: 'response', responseType: 'json' })
      .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
  }

  /** Run a PUT API call
   * @param route The endpoint for the request (ie. - '/v1/reports_recent')
   * @param body The object that is being updated
   * @param action The action that is performing the request
   * @return A response containing the expected result (single)
   */
  put<TResponseModel>(route: string, body: any, action = 'error putting request'): Observable<HttpResponse<TResponseModel>> {
    const url = this.rootUrl + route;
    return this.http.put<TResponseModel>(url, body, { params: this.newParams(), observe: 'response', responseType: 'json' })
      .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
  }

  /** Run a POST API call
   * @param route The endpoint for the request (ie. - '/v1/reports_recent')
   * @param body The object that is being posted
   * @param params The http params for the request
   * @param action The action that is performing the request
   * @return A response containing the expected result (single)
   */
  post<TResponseModel>(route: string, body: any, headers?: any, action = 'error posting request'): Observable<HttpResponse<TResponseModel>> {
    const url = this.rootUrl + route;
    return this.http.post<TResponseModel>(url, body, { headers: headers, params: this.newParams(), observe: 'response', responseType: 'json' })
      .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
  }

  /** Run a POST API call without piping to the errorHandler service (unless 401 received) so it returns the full response
   * @param route The endpoint for the request (ie. - '/v1/reports_recent')
   * @param body The object that is being posted
   * @param headers The http request headers for the request
   * @param action The action that is performing the request
   * @param withCredentials Use credentials
   * @return A response containing the full result (single)
   */
   postUnauthorizedHandler<TResponseModel>(route: string, body: any, headers?: any, action = 'error posting request', withCredentials = false): Observable<HttpResponse<TResponseModel>> {
    const url = this.rootUrl + route;
    return this.http.post<TResponseModel>(url, body, { headers: headers, params: this.newParams(), observe: 'response', responseType: 'json', withCredentials: withCredentials })
    .pipe(
      catchError(
        (error: HttpErrorResponse): Observable<any> => {
          if (error.status === 401) {
            return of(this.errorHandler.handleHttpErrorResponse(action, false)(error, null));
          }
          return throwError(() => error);
        }));
      }

  /** Run an external POST API call
   * @param route The endpoint for the request (ie. - '/v1/reports_recent')
   * @param body The object that is being posted
   * @param action The action that is performing the request
   * @param withCredentials Use credentials
   * @return A response containing the expected result (single)
   */
  postExt<TResponseModel>(route: string, body: any, action = 'error posting request', withCredentials = false): Observable<HttpResponse<TResponseModel>> {
    const url = this.rootUrl + route;
    return this.http.post<TResponseModel>(url, body, { params: this.newParams(), observe: 'response', responseType: 'json', withCredentials: withCredentials })
      .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
  }
  /** Run a POST API call without piping to the errorHandler service (unless 401 received) so it returns the full response
   * @param route The endpoint for the request (ie. - '/v1/reports_recent')
   * @param body The object that is being posted
   * @param params The http params for the request
   * @param action The action that is performing the request
   * @return A response containing the full result (single)
   */
  postExtsion<TResponseModel>(route: string, body: any, headers?: any, action = 'error posting request'): Observable<HttpResponse<TResponseModel>> {
    return this.http.post<TResponseModel>(route, body, { headers: headers, params: this.newParams(), observe: 'response', responseType: 'json' })
    .pipe(
      catchError(
        (error: HttpErrorResponse): Observable<any> => {
          if (error.status === 401) {
            return of(this.errorHandler.handleHttpErrorResponse(action, false)(error, null));
          }
          return throwError(() => error);
        }));
  }

    /** Run an external GET API call
   * @param url The endpoint for the request
   * @param action The action that is performing the request
   * @param params The http params for the request
   * @param headers The http request headers for the request
   * @return A response containing the expected result (single)
   */
  getExt<TResponseModel>(url: string, action: string = 'error executing requests',
  params: HttpParams = new HttpParams(), headers: HttpHeaders = new HttpHeaders(), withCredentials = false): Observable<HttpResponse<TResponseModel>> {
    return this.http.get<TResponseModel>(url, { headers: headers, params: params, observe: 'response', responseType: 'json', withCredentials: withCredentials })
    .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
  }

  /** Run a DELETE API call
   * @param route The endpoint for the delete request
   * @param action The action that is performing the request
   * @return A response containing the expected result
   */
  delete<TResponseModel>(route: string, action = 'error delete request'): Observable<HttpResponse<TResponseModel>> {
    const url = this.rootUrl + route;
    return this.http.delete<TResponseModel>(url, { params: this.newParams(), observe: 'response', responseType: 'json' })
      .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
  }

  /** Run a GET API call without piping to the errorHandler service so it returns the full response
   * @param route The endpoint for the request (ie. - '/v1/reports_recent)
   * @param action The action that is performing the request
   * @return A response containing the expected model (single)
   */
  getNoErrorHandler<TResponseModel>(route: string, action = 'error executing requests'): Observable<HttpResponse<TResponseModel>> {
    return this.http.get<TResponseModel>(this.rootUrl + route, { params: this.newParams(), observe: 'response', responseType: 'json' });
  }

  /** Run a GET API call with options
   * @param route The url for the request
   * @param action The action that is performing the request
   * @param params The http params for the request
   * @param headers The http request headers for the request
   * @return A response containing the expected model (single)
   */
  getWithOptions<TResponseModel>(route: string, action: string = 'error executing requests',
  params: HttpParams = new HttpParams(), headers: HttpHeaders = new HttpHeaders()): Observable<HttpResponse<TResponseModel>> {
    return this.http.get<TResponseModel>(this.rootUrl + route, { headers: headers, params: params, observe: 'response', responseType: 'json' })
      .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
  }
}
