import { Component, OnInit, ViewEncapsulation, Input, OnDestroy } from '@angular/core';
import { copyright } from 'src/app/core/constants';
import { UserPreferencesService } from 'src/app/core/services/user-preferences.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { ManageCookieLogoService } from 'src/app/core/services/manage-cookie-logo.service';
import { FeatureFlagService } from 'src/app/core/services/feature-flag.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit, OnDestroy {
  /** Determines if clicking logout should redirect */
  @Input() redirect: boolean;
  /** Subscription for preference */
  prefSub: Subscription;
  /* MobilifyHR Logo */
  mobilifyHRLogo = false;
  /* Move Pro 360 Logo */
  movePro360Logo = false;
  /* Benefits Builder Logo */
  benefitsBuilderLogo = false;
  /* Supplier Portal Logo */
  supplierPortalLogo = false;
  /* Atlas Logo */
  atlasLogo = false;
  /*For the source app URL */
  referredURL: string = '';
  /*Available Sites*/
  authorizedLocations = ['movePro360', 'mobilifyHR', 'mobilifyUI', 'supplierPortal', 'benefitsBuilder', 'compensationServices', 'atlas'];
  /*For showing logout button */
  showLogoutButton: boolean = false;
  /**property to print copyright string */
  readonly copyright = copyright;
  /** Used to hold logo config value */
  @Input() logoConfig: any;
  /** Used to store appId */
  appId: number;

  constructor(
    private readonly userPreferencesService: UserPreferencesService,
    private userPreference: UserPreferencesService,
    private readonly appConfig: AppConfigService,
    private router: Router,
    private readonly cookieService: CookieService,
    private readonly cookieLogoSvc: ManageCookieLogoService,
    private readonly featureFlagService: FeatureFlagService,
  ) { }

  async ngOnInit() {
    this.appId = (sessionStorage.getItem('appId')) ? parseInt(sessionStorage.getItem('appId').replace(/"/g, "")) : 0;
    // Listener for removal of token cookie
    window.addEventListener('storage', this.checkTokenCookie, true);
    // this.prefSub = this.userPreferencesService.getPreference('referrer', false).subscribe(val => {
    //   if (val) {
    //     this.referredURL = val.replace(/(\/#|\/|#)$/, '');
    //     const res = this.cookieLogoSvc.setLogo(this.referredURL);
    //     this.logoConfig = { logo: res, type: 'alt' }
    //   } else {
    //     this.logoConfig = { logo: this.logoConfig.logo, type: this.logoConfig.type };
    //   }
    // }); Commented out to display cartus logo in all the pages

    this.logoConfig = { logo: 'cartus', type: 'main' }

    // Show logout button if cookie is set via logging in
    if (this.cookieService.get('car-ses-tok')) {
      this.showLogoutButton = true;
    }

  }

  /* performs a redirect to logout */
  logout(): void {
    this.router.navigateByUrl('logout');
  }

  /* clears the cookies */
  clearCookies(): void {
    this.cookieLogoSvc.removeCookies(['car-ses-tok']);
  }

  // checkTokenCookie determines whether the logout button should be displayed
  // it is a variable holding the anonymous function for the event listener
  // this allows storage actions to be assigned to the event during onInit
  // and removed onDestroy
  checkTokenCookie = (event: any): void => {
    if (this.cookieService.get('car-ses-tok')) {
      this.showLogoutButton = true;
    } else {
      this.showLogoutButton = false;
      this.clearCookies();
      sessionStorage.clear();
    }
  }

  /** To unsubscribe to the event listener */
  ngOnDestroy(): void {
    window.removeEventListener('storage', this.checkTokenCookie, true);
  }

}
