<ngx-spinner type="ball-spin-clockwise-fade" size="medium"></ngx-spinner>
<app-header [redirect]="false" [logoConfig]="logoConfig" role="navigation"></app-header>
<div class="privacy-notice">
    <main class="main-content">
        <h1 class="heading">
            Cartus’ Privacy Policy for Use and Transfer of Personal Information
        </h1>
        <p class="text">
            Cartus Corporation, including our affiliated relocation companies (“Cartus”), strives to properly address
            privacy and data protection requirements that may apply to the relocation service we provide under contract
            to the employees of our corporate clients. This document describes how Cartus collects, uses, discloses,
            retains, and transfers Personal Information (as defined below) in performing relocation services.
        </p>
        <h2 class="sub-heading">
            Personal Information
        </h2>
        <p class="text">
            By “Personal Information” we mean any information that may directly identify you, any designee you appoint
            to manage your mobilify account or a member of your family, including name, address, mobile or other
            telephone number, e-mail address, government identification number, date of birth, photograph, financial
            account information, or other similar data elements that may be created or made available in connection
            with your relocation. We ask that you refrain from providing us with sensitive personal information
            about you or members of your family, such as your ethnicity, religion, health condition, and/or sexual
            orientation, as these data elements are not usually needed for your relocation. In the event it is
            necessary for you to provide us with sensitive personal information for your relocation, (i) if you are
            a resident of the European Union or Switzerland, Cartus will use this information as part of your
            relocation to provide the services requested by your employer, our corporate client, or (ii) if you are
            not a resident of the EU or Switzerland, by signing below you explicitly consent to Cartus’ use of that
            information for the Relocation Purpose.
        </p>
        <h2 class="sub-heading">
            Use of Your Personal Information
        </h2>
        <p class="text">
            We process your information as necessary for Cartus to provide relocation services under the terms of its
            contractual obligations with your employer consistent with the legitimate interests of your employer (i.e.,
            our corporate or government entity client). If you provide us with sensitive personal information, we will
            process it based on the instructions received from your employer in order to provide relocation services or
            your consent, as applicable. We will collect, use, disclose, retain and transfer Personal Information only
            for purposes of managing and facilitating your move from one location to a new location (the “Relocation
            Purpose”), including the following to the extent authorized by your employer: (a) arranging for your
            physical move and the storage of your property; (b) tracking and processing expense reimbursements; (c) home
            sales, purchases, and rentals, including financing arrangements; (d) immigration and other customs related
            purposes;
            (e) cultural assistance and training; and (f) other activities relating to the delivery and improvement of
            relocation
            services. Any access to your
            Personal Information is restricted to those individuals that have a need to know to fulfill their job
            responsibilities. We will not use Personal Information for any other purpose incompatible with the
            Relocation Purpose outlined in this Notice, unless it is required or authorized by laws that apply to Cartus
            or otherwise authorized by you.
        </p>
        <h2 class="sub-heading">
            Transfer and Protection of Personal Information
        </h2>
        <p class="text">
            We may transfer Personal Information to one or more of our offices or our service providers outside of your
            home country for any legitimate reason related to the Relocation Purpose. In addition, your information may
            be stored and secured in databases outside of your home country, including the United States of America. For
            example, we may disclose Personal Information (a) to local subcontractors of Cartus who provide relocation
            services in countries in which Cartus does not have offices; (b) to a third party service provider with
            facilities in India which processes Personal Information for the Relocation Purpose <strong>(e.g.,
                <em>supplier</em> invoices);</strong>
            or (c) to governmental authorities, courts, external advisors, or other similar
            parties located in or outside of your home country. We exercise appropriate due diligence in selecting our
            third party service providers, and contractually require them to treat your Personal Information
            confidentially, and implement appropriate technical, organizational, and administrative controls to
            safeguard it against unauthorized access, use, or disclosure. We retain your Personal Information only to
            the extent necessary to perform our contractual obligations in accordance with our Record Retention Policy,
            and consistent with the compliance obligations that may apply to our multi-jurisdictional business. When we
            no longer need it, we will securely remove, deactivate, or de-identify your Personal Information from our
            systems.
        </p>

        <p class="text">
            In addition, Cartus participates in the EU-U.S. Privacy Shield Framework and the Swiss-US Privacy Shield
            Framework (the "Framework"). Cartus' participation in the Framework applies to Personal Information about
            European Union (EU) and Swiss data subjects (collectively, "EU and Swiss Personal Data") that we receive
            from the EU or Switzerland in reliance on the Framework. We are committed to subjecting EU and Swiss
            Personal Data to the Framework, including its Principles of Notice, Choice, Accountability for Onward
            Transfer, Security, Data Integrity and Purpose Limitation, Access, and Recourse, Enforcement and Liability.
            To learn more about the Framework, visit the U.S. Department of Commerce's Privacy Shield List at
            <span class="link-style"><a rel="noopener" target="_blank"
                    href="https://www.privacyshield.gov">www.privacyshield.gov</a></span>.
            </p>
        <h2 class="sub-heading">
            Your Rights
        </h2>
        <p class="text">
            You may access Personal Information we have about you, and rectify any inaccurate or incomplete information,
            directly on mobilify, if you are an mobilify user. Otherwise, requests should be made in writing
            and submitted
            as follows:
        </p>
        <p class="text no-margin">
            By e-mail to: <span class="link-style"><a
                    href="mailto:InformationProtection@cartus.com">InformationProtection@cartus.com;</a></span> By mail:
            Privacy Officer, Cartus, 100 Reserve Road,
            Danbury, CT 06810; or if your relocation is being handled by our UK affiliate, to Cartus Limited at
            Frankland Road, Blagrove, Swindon, Wiltshire SN5 8RS,
            </p>
        <p class="text no-margin">
            marked for the attention of Director of <strong>Legal Services.</strong>
        </p>
        <p class="text no-margin">
            By phone: (203) 205-1540
        </p>
        <p class="text">
            By phone (Singapore): +65 6880 5804
        </p>

        <p class="text">
            In addition, if you are a resident of the EU, you may have the following rights that you should exercise
            directly through the contacts listed above. Cartus will strive to aid your employer when you exercise the
            following rights:
        </p>
        <p class="text no-margin">
            The right to require a copy of your personal data;
        </p>
        <p class="text no-margin">
            The right to require that inaccurate or out of date personal data be corrected;
        </p>
        <p class="text no-margin">
            The right to request that personal data is erased when it is no longer necessary to be retained;
        </p>
        <p class="text no-margin">
            If consent is the basis for processing, the right to withdraw your consent;
        </p>
        <p class="text no-margin">
            The right to data portability, if applicable;
        </p>
        <p class="text no-margin">
            The right, where there is a dispute in relation to the accuracy or processing of your personal data, to
            request a restriction be placed on further processing;
        </p>
        <p class="text no-margin">
            The right to object to processing, if applicable; and
        </p>
        <p class="text">
            You also have the right to lodge a complaint with your applicable data protection authority.
        </p>
        <h2 class="sub-heading margin-bottom">
            Providing Personal Information to us is voluntary. By creating an account, you acknowledge that you
            understand the contents of this Notice of how Cartus will collect, use, disclose, retain, and transfer your
            Personal Information for the Relocation Purpose as set forth above as instructed by your employer, our
            corporate client; and to acknowledge your consent, if applicable.
        </h2>
        <h2 class="sub-heading">
            If you do not agree to Cartus using your Personal Information for the Relocation Purpose we set out above,
            you should not create an account. If you refuse to provide Cartus with your Personal Information, we will
            not be able to provide you with Relocation Services, and we will notify your employer (our corporate client)
            of your election.
        </h2>
    </main>
    <footer class="page-footer">
        {{copyright}}
    </footer>
</div>
