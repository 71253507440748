import { Component, OnInit, Output, EventEmitter, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { FormGroup, UntypedFormBuilder, Validators, FormControl } from '@angular/forms';
import { SelfRegistrationSetupService } from 'src/app/core/services/self-registration-setup.service';
import { User } from '../../../core/models/User';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
// import { PersistenceService, StorageType } from 'angular-persistence';
import { filter } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { ManageUserSharedService } from '../../../core/services/manage-user-shared.service';
import { ManageCookieLogoService } from 'src/app/core/services/manage-cookie-logo.service';
import { APIResponse } from 'src/app/core/models/APIResponse';
import { FeatureFlagService } from "src/app/core/services/feature-flag.service";
import { DelegateLegalAggreementService } from 'src/app/core/services/delegate-legal-aggreement.service';

/** Class for  SelfRegistration */
@Component({
  selector: 'app-self-registration',
  templateUrl: './self-registration.component.html',
  styleUrls: ['./self-registration.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SelfRegistrationComponent implements OnInit {
  /** Used to inject MatStepper */
  @ViewChild('stepper', { static: true }) stepper: MatStepper;
  /** Variable to store user Details */
  userDetails: User;
  /** Variable to store PartyId */
  partyId: string;
  /** Variable to store Step number */
  completedStep: number;
  /** Variable to to current stepping number */
  steppingId: number;
  /** subscription property for subscribing and unsubscribing services */
  private readonly subscription: Subscription = new Subscription();
  /** Used to hold logo config value */
  logoConfig: any;
  /** Used to hold loading state */
  isLoading: boolean = true;
  // roleName  
  roleName: any;
  //delegateConstants
  delegateUser: any;
  //federated delegate value
  isDelegateFederated: number = 0;
  /** string constants to be displayed in self registration page */
  templateString = {
    WELCOME: 'Welcome to Cartus – A leader in global mobility!',
    INFORMATION: `Our primary goal is to provide you with world-class service whether you are one of our corporate client ` +
      `contacts, their employee or our supplier partner. You will benefit from our 60+ years of experience as we help ` +
      `navigate you through all phases of the relocation process.`,
    BANNER_TXT: `Technology That Moves You`
  };
  ALREADY_REGISTERED: APIResponse = { statusCode: 400, message: 'You have already registered.\nPlease click the below to log into your account.' };
  LINK_EXPIRED: APIResponse = { statusCode: 410, message: 'The link has expired.\nPlease contact your Cartus representative or designator to send a new invitation' };
  NOT_FOUND: APIResponse = { statusCode: 404, message: 'Please contact the Cartus IT Help Desk for assistance.\nWe are unable to process your request.' };
  API_ERROR: APIResponse = { statusCode: 500, message: 'We are unable to process your request at this time\nPlease try again later' };

  //** GetLoginApp feture flag status */
  loginAppFlag: boolean = false;
  /** Output parameter step number notified to parent component by emiting */
  @Output() notify: EventEmitter<number> = new EventEmitter<number>();
  /**
     * Base constructor
     * @param fb Formbuilder variable
     * @param location location service
     * @param spinner to get NgxSpinner
     * @param registrationSetupService SelfRegistrationSetupService
     * @param activatedRoute To route to the particular location
     */
  constructor(private readonly fb: UntypedFormBuilder,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly registrationSetupService: SelfRegistrationSetupService,
    private readonly location: Location,
    // private readonly persistenceService: PersistenceService,
    private readonly spinner: NgxSpinnerService,
    private readonly snackBar: MatSnackBar,
    private readonly manageMoveSharedService: ManageUserSharedService,
    private readonly cookieLogoSvc: ManageCookieLogoService,
    private featureFlag: FeatureFlagService,
    private readonly delegateLegalSetupService: DelegateLegalAggreementService) {
    this.getFeatureFlagValue();
    this.router.events
      .pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd))
      .subscribe(event => {
        // if ((event.url === event.urlAfterRedirects) && (this.persistenceService.get('completedStep', StorageType.SESSION))) {
        if ((event.url === event.urlAfterRedirects) && (sessionStorage.getItem('completedStep'))) {
          const lastCompletedStep = JSON.parse(
            // this.persistenceService.get('completedStep', StorageType.SESSION)
            sessionStorage.getItem('completedStep')
          );
          this.completedStep = lastCompletedStep;
        }
      });
  }

  /** To Initialize Component */
  async ngOnInit() {
    this.spinner.show();
    this.partyId = this.activatedRoute.snapshot.url[1].path;
    let currentStep;
    this.completedStep = this.completedStep
      ? this.completedStep
      : 0;
    this.subscription.add(
      this.activatedRoute.params.subscribe(params => {
        if (!params['id']) {
          this.changeStep(0);
          // this.persistenceService.set('currentStep', JSON.stringify(0), { type: StorageType.SESSION });
          sessionStorage.setItem('currentStep', JSON.stringify(0));
        } else {
          // currentStep = parseInt(this.persistenceService.get('currentStep', StorageType.SESSION), 10);
          currentStep = parseInt(sessionStorage.getItem('currentStep'), 10);
          this.steppingId = parseInt(params['id'], 10);
          if (currentStep === this.steppingId) {
            this.changeStep(this.steppingId);
          } else {
            this.changeStep(currentStep);
          }
        }
      })
    );
    await this.getUserDetails(this.partyId);
  }
  getFeatureFlagValue() {
    this.featureFlag.getLoginApp('enable-login-app').then(res => {
      this.loginAppFlag = res;
    });
  }
  /**
   * To set the selected index
   * @param event - selected index
   */
  public onStepChange(event: any): void {
    const stepVal = `/registration/${this.partyId}/step/${event.selectedIndex}`;
    this.location.go(stepVal);
  }

  /**
   * to find completed step and change step
   * @param completedStep - step completed
   */
  onNotify(completedStep: number): void {
    // this.persistenceService.set('completedStep', JSON.stringify(completedStep),
    //   { type: StorageType.SESSION });
    sessionStorage.setItem('completedStep', JSON.stringify(completedStep));

    if (completedStep === 0) {
      this.changeStep(1);
    } else if (completedStep === 1) {
      this.changeStep(2);
    }
  }

  onDelegateNotify(): void {

    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      sessionStorage.setItem('currentStep', JSON.stringify(1));
      this.router.navigate([`/registration/${this.partyId}/step/1`]);
    });
  }

  /**
   * Used to go to given index
   * @param index selected index
   */
  changeStep(index: number): void {
    this.stepper.selectedIndex = index;
    const stepVal = `/registration/${this.partyId}/step/${index}`;
    this.location.go(stepVal);
  }

  /**
  * To get user details
  * @param partyId - partyId
  */
  getUserDetails(partyId: string) {
    this.subscription.add(
      this.registrationSetupService
        .getUser(partyId)
        .subscribe(async (userDetails: User) => {
          if (userDetails) {
            this.userDetails = userDetails;
            this.roleName = this.userDetails.roleName;
            const res = this.cookieLogoSvc.setLogoFromUser(this.userDetails);
            this.logoConfig = { logo: res, type: 'main' }
            this.manageMoveSharedService.updateData(this.userDetails);
            if (this.loginAppFlag && (this.userDetails.roleName === 'client-contact' || this.userDetails.roleName === 'client-contact-initiator')) {
              this.emailExpiryOnResentInvite(this.userDetails)
            } else {
              await this.validateUserRegistration(this.userDetails.userId);
            }

            if (this.loginAppFlag && this.userDetails.roleName === 'delegate') {
              await this.delegateUserRedirect(this.userDetails.userId, this.userDetails.actionReferenceId)
            } else {
              this.isLoading = false;
              this.spinner.hide();
            }

          } else {
            this.spinner.hide();
            this.registrationSetupService.currentError = this.NOT_FOUND;
            this.router.navigate(['invalidRegistration']);
          }
        })
    );
  }

  delegateUserRedirect(partyId: string, actionReferenceId: number) {
    this.subscription.add(
      this.delegateLegalSetupService
        .getDelegateConsentFlag(partyId, actionReferenceId)
        .subscribe((response: any) => {
          if (response) {
            this.delegateUser = response.B2BAPIResponse.DelegateConsent;
            if (this.delegateUser === "DECLINED") {
              this.router.navigate(['/DelegateOptOut']);
            } else if (this.delegateUser === "ACCEPTED") {
              let currentStep;
              currentStep = parseInt(sessionStorage.getItem('currentStep'), 10);
              this.notify.emit(currentStep);
            }
            this.isLoading = false;
            this.spinner.hide();
          }
        })
    );
  }

  navigateToInvalidRegistration() {
    this.registrationSetupService.currentError = this.API_ERROR;
    this.spinner.hide();
    this.router.navigate(['invalidRegistration']);
  }

  /**
 * To validate registration
 * @param partyId - partyId
 */
  validateUserRegistration(partyId: string) {
    this.subscription.add(
      this.registrationSetupService
        .validateRegistration(partyId)
        .subscribe({
          next: response => {
            if (response && response.error && response.error.message) {
              let message: string = response.error.message.toLowerCase();
              if (response.status === 400 && message.includes('this user is already registered')) {
                this.registrationSetupService.currentError = this.ALREADY_REGISTERED;
              } else if (response.status === 400 && message.includes('self registration has expired')) {
                this.registrationSetupService.currentError = this.LINK_EXPIRED;
              } else if (response.status === 404) {
                this.registrationSetupService.currentError = this.NOT_FOUND;
              } else {
                this.registrationSetupService.currentError = this.API_ERROR;
              }
              this.spinner.hide();
              this.router.navigate(['invalidRegistration']);
            }
          },
          error: () => {
            this.navigateToInvalidRegistration()
          }
        })
    );
  }

  emailExpiryOnResentInvite(userDetails: User) {
    this.subscription.add(
      this.registrationSetupService
        .expireRegistrationInvite(userDetails)
        .subscribe({
          next: response => {
            if (response) {
              let message: string = response.message.toLowerCase();
              if (message.includes('invitation expired')) {
                this.registrationSetupService.currentError = this.LINK_EXPIRED;
                this.spinner.hide();
                this.router.navigate(['invalidRegistration']);
              }
              else {
                this.validateUserRegistration(userDetails.userId);
              }
            }
          },
          error: () => {
            this.navigateToInvalidRegistration()
          }
        })
    );
  }
}
